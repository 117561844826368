<template>
    <b-modal id="addLinkModal" hide-footer title="Link URL to Document" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex" >
                <span style="font-weight: bold; margin-left: 2px">Insert Link URL/Anchor to Document</span>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('addLinkModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        <div>
                <b-overlay :show="is_loading">
                <div class="d-flex">
                    <b-form-input size="sm" v-model.lazy="filter_input" placeholder="Enter URL/Anchor tag"></b-form-input>
                    <b-button size="sm" variant="warning" @click="filter_input = ''" class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div>
                <b-button @click="handleInsert()" class='ms-auto mt-2' style="float: right" variant='primary'>Insert into editor</b-button>
                </b-overlay>
            </div>
    </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
//mport draggable from 'vuedraggable';
import { levelComputed, peopleComputed, peopleMethods, handbookMethods, handbookComputed } from '@/state/helpers';
//import _ from 'lodash'
export default {
mixins: [dateMixin],
components: {
    //VueTypeaheadBootstrap
},
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    filter_input: '',
    users_example:[],
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    change_objective_mode: false,
    canSearch: false,
    parent_name: '',
    notes: '',
    changesMade: false,
    selectedName: '',
    selectedType: '',
    viewChildren: false,
    level_data: [],
    query: '',
    docs: []
}),
watch:{
},
computed:{
    ...levelComputed,
    ...peopleComputed,
    ...handbookComputed,
},
methods:{
    ...handbookMethods,
    ...peopleMethods,
    handleInsert() {
        let data = {id: this.payload.id, text: this.filter_input, name: this.payload.name}
        this.$emit('handleInsert', data)
        this.$nextTick(() => {
            this.$bvModal.hide('addLinkModal')
        })
    }
},
beforeDestroy() {
},
mounted(){
    
    console.log(JSON.parse(JSON.stringify(this.payload)))
},
}
</script>
<style>
</style>