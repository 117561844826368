<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { menuMethods } from "@/state/helpers";

import Process from "@/components/process/process.vue"
import OrganisationChart from '@/components/widgets/graphs/organisation-chart.vue'
import { handbookMethods, profileComputed, profileMethods } from '@/state/helpers'
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Documents",
    },
    components: {
        Layout,
        //eslint-disable-next-line
        PageHeader,
        Process,
        OrganisationChart       
    },
    computed:{
        ...profileComputed,
        mustUseUrlParam(){
            if(this.$route.params.document_param == undefined){
                return false;
            }
            else{
                return this.$route.params.document_param
            }
        }
    },
    data() {
        return {
            title: "Documents",
            items: [
                /*{
                    text: "Home",
                    href: "/",
                },
                {
                    text: "Activity",
                    active: true,
                },*/
            ],
        };
    },
    methods:{
        ...handbookMethods,
        ...menuMethods,
        ...profileMethods
    },
    beforeDestroy() {
        this.documents = null
        this.hideOrgChart(false)
    },
    mounted() {
        //this.$eventHub.$emit('menuPageLoaded');
        setTimeout(()=>{
            this.setLoading(false);
        }, 500)
        
    },
};
</script>

<template>
    <Layout>
        <!--<PageHeader :title="title" :items="items" /> -->
        <OrganisationChart v-if="hiddenOrgChart"/>
        <Process :urlParam="mustUseUrlParam" v-if="!hiddenOrgChart" />
    </Layout>
</template>
