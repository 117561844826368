<template>
    <b-modal title='Update Document' id='documentDetailsModal' hide-footer>
        <b-row v-if="doc_details != null">
            <b-col cols="12">
                <label class="mb-0" for="">Title:</label>
                <b-form-input v-model="doc_details.title" size="sm" type="text"></b-form-input>
            </b-col>
            <b-col cols="12">
                <label class="mb-0 mt-2" for="">Type:</label>
                <b-form-select v-model="doc_details.type" size="sm" class="make-sm form-select" :options="type_options"></b-form-select>
            </b-col>
            <b-col cols="12" class="typeahead-column-parent">
                <label class="mb-0 mt-2" for="">Owner:</label>
                <vue-typeahead-bootstrap
                    ref="userSearch"
                    :data="typeahead_users"
                    v-model="query"
                    placeholder="Search name/email"
                    showOnFocus
                    @hit="selectedItemEvent"
                ></vue-typeahead-bootstrap>
            </b-col>
            <!--<b-col cols="12" class="mt-2">
                <label class="mb-0" for="">Owner:</label>
                <b-form-input v-model="details_edit.title" size="sm" type="text"></b-form-input>
            </b-col>-->
             <b-col cols="12">
                <label class="mb-0 mt-2" for="">Review Date:</label>
                <b-form-group size="sm" >
                    <b-form-input
                    id="input-3"
                    size="sm"
                    type="date"
                    v-model="doc_details.review_date"
                    placeholder="Select Review Date"
                    required
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-2">
                <label class="mb-0 mt-2" for="">Structures:</label>
                <div>
                    <font-awesome-icon class="fa-fw edit-pen-icon" icon="fa-pen" @click="showHierarchyModal()"/>
                    <span>{{getLevelNameForIds(doc_details.level_ids)}}</span>
                </div>
                <!--<b-form-input v-model="details_edit.level_ids" size="sm" type="text"></b-form-input>-->
            </b-col>
        </b-row>
        <!-- footer -->
        <div class='d-flex'>
            <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
        </div>
        <HierarchyModal
            @updatedLevels="handleRowLevelsUpdate"
            :current_row="doc_details"
            v-if="show_hierarchy_modal"
        />
    </b-modal>

</template>

<script>
import { cloneDeep, find } from 'lodash'
import { levelComputed } from '@/state/helpers'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'
export default {
    props:{
        edit_document:{
            required: true,
        }
    },
    components:{
        VueTypeaheadBootstrap,
        HierarchyModal,
    },
    data:() => ({
        doc_details:null,
        typeahead_users: [],
        query: '',

        //modal for linking
        show_hierarchy_modal: false,
    }),
    computed:{
        ...levelComputed,
        type_options(){
            return [
                { value: 'policy', text: 'Policy' },
                { value: 'process', text: 'Process' },
                { value: 'procedure', text: 'Procedure' },
                { value: 'guideline', text: 'Guideline' }
            ]
        },
    },
    methods:{
        save(){
            this.$emit('updatedDocumentDetails', {
                title: this.doc_details.title,
                type: this.doc_details.type,
                owner_user_id: this.doc_details.owner_user_id,
                level_ids: this.doc_details.level_ids,
                review_date: this.doc_details.review_date
            })
        },

        showHierarchyModal(){
            this.show_hierarchy_modal = true;
            this.$nextTick(()=>{
                this.show_hierarchy_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('levelLinkingModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
                            this.show_hierarchy_modal = false;
                        }
                    });
                })
            })
        },
        handleRowLevelsUpdate(payload){
            this.doc_details.level_ids = payload;
            this.$swal.fire({
                icon:'success',
                title:'Updated linked structure',
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            })
            this.$bvModal.hide('levelLinkingModal');
        },

        // misc helpers
        getLevelNameForIds(id_array){
            let names = '';
            id_array.forEach((id, idx) => {
                let lvl = find(this.levels, {id: id});
                if(lvl && idx == 0){
                    names += lvl.name;
                }
                else if(lvl && idx > 0){
                    names += `, ${lvl.name}`;
                }
            });
            return names;
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                users.push(`${user.name} | ${user.email}`)
            });
            this.typeahead_users = users;
        },
        selectedItemEvent(item){
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let email = item.split('| ')[1];
            let user = this.all_users.find( user => {
                return user.email == email;
            });
            
            if(user){
                this.doc_details.owner_user_id = user.id;
            }
        },
        resetTypeahead(){
            this.$nextTick(()=>{
                this.query = '';
                let input = this.$refs['userSearch'].$refs['input'];
                input.blur();
            })
        },
        setInitialTypeaheadValue(){
            let user = find(this.all_users, {id: this.edit_document.owner_user_id});
            this.query = `${user.name} | ${user.email}`
        },
    },
    mounted(){
        this.populateList();
        this.setInitialTypeaheadValue();
        this.doc_details = cloneDeep(this.edit_document)
    },
}
</script>

<style>
.typeahead-column-parent>div>div>input{
    font-size: 0.65625rem !important;
    padding: 0.25rem 0.5rem;
}
.edit-pen-icon{
    cursor: pointer;
    padding-right: 0.3rem;
}
.edit-pen-icon:hover{
    color: var(--bs-primary);
}
</style>