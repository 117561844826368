<template>
    <div>
        <b-modal :static="true" :no-enforce-focus="true" size="lg" title='File Manager' id='fileManager' hide-footer>
            <b-overlay :show="busyLoading">
                <!-- <div class='d-flex' v-if="editable == false">
                <b-button @click="$bvModal.hide('notesModal')" class='ms-auto mt-2' variant='primary'>Close</b-button>
               </div> -->
                <div class="d-xl-flex">
                    <div class="w-100">
                        <div class="d-md-flex">

                            <div class="w-100">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <div class="row mb-3">
                                                <div class="col-xl-3 col-sm-6">
                                                    <!-- <div class="mt-2">
                                                        <h5>Document Files</h5>
                                                    </div> -->
                                                </div>
                                                <div class="col-xl-9 col-sm-6">
                                                    <form class="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                                                        <!-- <div class="search-box mb-2 me-2" v-show="currentAction == 'view_files'">
                                                            <div class="position-relative">
                                                                <input type="text"
                                                                    class="form-control bg-light border-light rounded"
                                                                    placeholder="Search..." />
                                                                <i class="bx bx-search-alt search-icon"></i>
                                                            </div>
                                                        </div> --> 

                                                        <b-button v-show="currentAction == 'view_files'" variant="primary" size="md" @click="setAction('upload_files')"><i class="mdi mdi-cloud-upload me-1"></i> Upload New</b-button>
                                                        <b-button v-show="currentAction == 'upload_files'" variant="primary" size="md" @click="setAction('view_files')"><i class="mdi mdi-keyboard-backspaceme-1"></i> Back</b-button>
                                                        <!-- <b-dropdown class="mb-0" toggle-class="btn btn-link text-muted"
                                                            menu-class="dropdown-menu-end" right variant="white">
                                                            <template #button-content>
                                                                <i class="mdi mdi-dots-vertical font-size-20"></i>
                                                            </template>
                                                            <b-dropdown-item href="#">Share Files</b-dropdown-item>
                                                            <b-dropdown-item href="#">Share with me</b-dropdown-item>
                                                            <b-dropdown-item href="#">Other Actions</b-dropdown-item>
                                                        </b-dropdown> -->
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="row" v-show="currentAction == 'view_files'">
                                                <div style="text-align: center;" class="col-sm-12" v-show="media_list.length == 0">
                                                    <h3>Please upload some files</h3>
                                                </div>
                                                <div v-show="media_list.length > 0" v-for="media in media_list" :key="media.id" class="col-xl-4 col-sm-6">
                                                    <div class="card shadow-none border">
                                                        <div class="card-body p-3">
                                                            <div class="">
                                                                <div class="float-end ms-2">
                                                                    <b-dropdown toggle-class="font-size-16 text-muted p-0"
                                                                        menu-class="dropdown-menu-end" class="mb-2"
                                                                        variant="white" right>
                                                                        <template #button-content>
                                                                            <i class="mdi mdi-dots-horizontal"></i>
                                                                        </template>
                                                                        <b-dropdown-item @click="insertFile(media)">Insert</b-dropdown-item>
                                                                        <b-dropdown-item @click="openFile(media.url)">Open</b-dropdown-item>
                                                                        <b-dropdown-item @click="renameMedia(media.id)">Rename</b-dropdown-item>
                                                                        <b-dropdown-divider></b-dropdown-divider>
                                                                        <b-dropdown-item variant="danger" @click="deleteFile(media)">Delete</b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>
                                                                <div class="avatar-sm me-3 mb-3">
                                                                    <div v-show="media.filetype == 'pdf'" class="avatar-title bg-transparent rounded">
                                                                        <i class="mdi mdi-file-pdf font-size-36 text-primary"></i>
                                                                    </div>
                                                                    <div v-show="media.filetype == 'image'" class="avatar-title bg-transparent rounded">
                                                                        <img class="listThumbnail" :src="media.url" alt="Image" />
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex">
                                                                    <div class="overflow-hidden me-auto">
                                                                        <h5 class="font-size-14 text-truncate mb-1">
                                                                            <span>{{ media.filename }}</span>
                                                                        </h5>
                                                                        <p class="text-muted text-truncate mb-0">
                                                                            File Type: {{ media.filetype.toUpperCase() }}
                                                                        </p>
                                                                    </div>
                                                                    <!-- <div class="align-self-end ms-2">
                                                                        <p class="text-muted mb-0">6GB</p>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end col -->
                                            </div>
                                            <!-- end row -->
                                            <div class="row" v-show="currentAction == 'upload_files'">
                                                <uploader :options="uploadOptions" ref="media_upload" :autoStart="true" class="uploader-example">
                                                    <uploader-unsupport></uploader-unsupport>
                                                    <uploader-drop>
                                                        <p>Drop file here to upload or</p>
                                                        <uploader-btn type="button" :single="false">Select file</uploader-btn>
                                                        <p class="mt-1 mb-0">Supported files: .jpg, .png, .pdf</p>
                                                    </uploader-drop>
                                                    <uploader-list></uploader-list>
                                                </uploader>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- end card -->
                            </div>
                            <!-- end w-100 -->
                        </div>
                    </div>

                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>
<style scoped>
.listThumbnail {
    width: 80px; /* Set the desired width for the thumbnail */
    height: auto; /* Allow the height to adjust proportionally */
}
.font-size-36 {
    font-size: 36px !important;
}
</style>
<script>
import _ from 'lodash';
import {  handbookMethods, handbookComputed } from "@/state/helpers";
import Swal from "sweetalert2";
export default {
    data: () => ({
        currentAction: "view_files",
        busyLoading: true,
    }),
    props: {
        document_id: {
            required: true 
        },
        body_content: {
            required: true 
        }
    },
    computed: {
        ...handbookComputed,
        uploadOptions() {
            let url = process.env.VUE_APP_API_BASEURL + 'api/documents/uploadchunkmedia';
            let options = {
                target: url,
                query: {
                    document_id: this.document.id,
                },
                testChunks: false,
                simultaneousUploads: 1, 
                fileParameterName: 'media', //Set the upload post param which will contain the binary payload
                singleFile: true, //Limit upload to one file
            }
            return options;
        }
    },
    methods: {
        ...handbookMethods,
        setAction(action) {
            this.currentAction = action;
        },
        openFile(url) {
            window.open(url);
        },
        countStrOccurrences(mainStr, subStr) {
            const splitStr = _.split(mainStr, subStr);
            return _.size(splitStr) - 1;
        },
        deleteFile(media) {
            let mediaStr = "";
            let url = media.url;
            if (media.filetype == "image") {
                mediaStr = `src="${url}"`;
            } else {
                mediaStr = `href="${url}"`;
            }
            let occcurenceCount = this.countStrOccurrences(this.body_content, mediaStr);
            Swal.fire({
                title: `Are you sure you want to delete
                            "${media.filename}" ?`,
                text: `It has ${occcurenceCount} occurrence(s) in the document!`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete!",
            }).then((result) => {
                if (result.value) {
                    this.busyLoading = true;
                    this.deleteDocumentMedia(media.id).then(() => {
                        this.$emit('removeMedia', media);
                        this.loadData();
                    });
                }
            });
        },
        insertFile(media) {
            if (media.filetype == "image") {
                this.$emit('insertImage', media.url);
            }
            if (media.filetype == "pdf") {
                this.handlePdfInsert(media);
            }
        },
        async handlePdfInsert(media) {
            const { value: name } = await this.$swal.fire({
                title: "Insert PDF Link",
                input: 'textarea',
                inputValue: '',
                inputPlaceholder: 'Link Name',
                confirmButtonText: "Insert",
                showCancelButton: true,
                didOpen: () => {
                    const input = this.$swal.getInput();
                    if (input) input.focus();
                },
                preConfirm: (name) => {
                    if (name.length == 0) {
                        this.$swal.showValidationMessage(
                            `Enter Document Link Name`
                        );
                    }
                }
            })
            if (name) {
                let insertParams = {
                    url: media.url,
                    linkName: name
                };
                this.$emit('insertPdf', insertParams);
            }
        },
        async renameMedia(id) {
            const { value: name } = await this.$swal.fire({
                title: "Rename Item",
                input: 'textarea',
                inputValue: '',
                inputPlaceholder: 'New Name',
                confirmButtonText: "Save",
                showCancelButton: true,
                didOpen: () => {
                    const input = this.$swal.getInput();
                    if (input) input.focus();
                },
                preConfirm: (name) => {
                    if (name.length == 0) {
                        this.$swal.showValidationMessage(
                            `Enter New Name`
                        );
                    }
                }
            })
            if (name) {
                let params = {
                    media_id: id, 
                    name: name
                };
                this.busyLoading = true;
                this.renameDocumentMedia(params).then(()=>{
                    this.loadData();
                });
            }
        },
        loadData() {
            this.busyLoading = true;
            this.listDocumentMedia(this.document_id).then(()=>{
                this.busyLoading = false;
                this.currentAction = "view_files";                
            });
        },
        mountActions() {
            let el = this;
            let uploaderInstance = this.$refs['media_upload'];
            let upload = uploaderInstance.uploader;
            // eslint-disable-next-line
            upload.on('fileAdded', function(file, event){
                let fileType = file.fileType;
                console.log(fileType);
                if (fileType != "application/pdf" && fileType != "image/png" && fileType != "image/jpg" && fileType != "image/jpeg") {
                    el.$swal.fire({
                        title: "Invalid File Type",
                        text: "Please select a valid file type (pdf, png, jpg, jpeg)",
                        icon: "warning",
                    })
                    return false;
                }
                let token = localStorage.getItem('x_token');
                upload.opts.headers = {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': 'Bearer ' + token,
                };
            });
            // eslint-disable-next-line
            upload.on('fileSuccess', function(rootFile, file, message, chunk){
                let response = JSON.parse(message);
                if (response.uploaded === 1) {
                    el.loadData();
                    upload.cancel();
                } else {
                    upload.cancel();
                }
            });
        }
    },
    mounted() {
        this.loadData();
        this.mountActions();
    },
}
</script>