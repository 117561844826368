<template>
    <b-modal id="addDocumentModal" hide-footer :title="payload.text" style="height: 100%;">
        <template #modal-header style="background-color: #EBEDF0;">
            <div class="w-100 d-flex" >
                <h6 class="m-1" style="font-weight: bold;"> Add New Document</h6> <br>   
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('addDocumentModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        
        <!-- <div class="modal-title">
           Details   
        </div> -->
        <div class="mt-0">
            <div>
                <div>
                    <b-form @submit="onSubmit" v-if="show">
                    <b-form-group class="label" id="input-group-1" label="Document Title:" label-for="input-1" size="sm">
                        <b-form-input
                        id="input-1"
                        size="sm"
                        v-model="form.title"
                        placeholder="Enter Document Title"
                        required
                        ></b-form-input>
                    </b-form-group>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm p-0">
                                <b-form-group id="input-group-2" label="Document Type:" label-for="input-2" size="sm" >
                                    <b-form-select class="make-sm form-select mr-2" style="width:90%; margin-right: 10px; min-height: 100%"
                                    id="input-2"
                                    v-model="form.document_type"
                                    placeholder="Enter Document Type"
                                    :options="type_options"
                                    required
                                    >
                                    </b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-sm p-0" style="margin-left: 4px;">
                                <b-form-group id="input-group-3" label="Owner:" label-for="input-3" size="sm" >
                                    <vue-typeahead-bootstrap
                                        ref="userSearch"
                                        :data="users"
                                        size="sm"
                                        v-model="owner_query"
                                        placeholder="Search name/email"
                                        @hit="selectedOwner"
                                        showOnFocus
                                    ></vue-typeahead-bootstrap>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-sm p-0">
                                <b-form-group id="input-group-4" label="Organisation Structure:" label-for="input-4" size="sm" >
                                <b-row>
                                    <b-col cols="8" style="margin-right: 5px; padding-right: 0px; height:100%; width: 75%">
                                        <b-form-input
                                            id="input-4"
                                            size="sm"
                                            v-model="getLevelForID"
                                            placeholder="Select Organisation Structure"
                                            required
                                            readonly
                                        ></b-form-input>
                                    </b-col>
                                    <b-col cols="4" style="display:flex; justify-content: center; padding: 0; margin: 0; width: auto">
                                        <b-button variant="primary" size="sm" @click="showHierarchyModal">
                                            <i class="fas fa-pen"></i>
                                        </b-button>
                                    </b-col>
                                    
                                </b-row>
                            </b-form-group>
                            </div>
                            <div class="col-sm p-0" style="margin-left: 4px;">
                                <b-form-group id="input-group-5" label="Review Date:" label-for="input-3" size="sm" >
                                    <b-form-input
                                    id="input-3"
                                    size="sm"
                                    type="date"
                                    v-model="form.review_date"
                                    placeholder="Select Review Date"
                                    required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-button type="submit" class='ms-auto mt-2' style="float: right" variant='success'>Submit</b-button>
                    </b-form>
                </div>
            </div>
        </div>
        <!-- <HierarchyModal :payload="hierarchy_payload"
            is_user_modal
            show_all_levels 
            v-if="show_hierarchy_modal"
            @selectedNewID="updateLevelId"
        /> -->
        <HierarchyModal @updatedLevels="updateLevelIds" :current_row="modal_payload" v-if="show_hierarchy_modal" />
    </b-modal>
</template>

<script>
import { levelComputed, levelMethods, activityComputed, activityMethods, profileComputed, profileMethods, handbookComputed, handbookMethods } from '@/state/helpers'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import HierarchyModal from '@/components/widgets/linkingModals/levels.vue'
// import { format, parseISO } from 'date-fns';
// import Swal from 'sweetalert2';
import dateMixin from '@/mixins/dateMixin.js'
import _ from 'lodash'
export default {
components:{
    VueTypeaheadBootstrap,
    HierarchyModal,
},
mixins:[ dateMixin ],
props:{
    payload:{
        required: false,
    },
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    users: [],
    filter_input: '',
    show_hierarchy_modal: false,
    assigned_level_id: -1,
    owner_query: '',
    type_options: {
            'policy': 'Policy',
            'process': 'Process',
            'procedure': 'Procedure',
            'guideline': 'Guideline',
    },
    user_query: '',
    form: {
        id: -1,
        level_ids: [],
        document_type: 'policy',
        owner: '',
        title: '',
    },
    show: true,
    level_ids: [],
    parent_item_id: -1,
    parent_item_type: '',
    parent_name: '',
}),
watch:{
},
computed:{
    ...levelComputed,
    ...activityComputed,
    ...profileComputed,
    ...handbookComputed,
    getLevelForID(){
        let id_array = this.form.level_ids
        let count = 1
        let level_names = [];
            let names_string = '';
            id_array.forEach(id => {
                let level = _.find(this.levels, { id: id });
                console.log(JSON.parse(JSON.stringify(level)))
                level_names.push(level.name);
            })
            level_names = level_names.sort();
            level_names.forEach((name, idx) => {
                if (idx == 0) {
                    names_string += name;
                }
                else {
                    count++
                    if(count > 0) {
                        names_string = `${count} Organisational Structures`
                    } else {
                        names_string += `${name}`
                    } 
                    
                }
            });
            return names_string
    },
    getLevelNamesForIds(id_array) {
            let level_names = [];
            let names_string = '';
            id_array.forEach(id => {
                let level = _.find(this.levels, { id: id });
                level_names.push(level.name);
            })
            level_names = level_names.sort();
            level_names.forEach((name, idx) => {
                if (idx == 0) {
                    names_string += name;
                }
                else {
                    names_string += `, ${name}`
                }
            });
            return names_string
        },
},
methods:{
    ...activityMethods,
    ...profileMethods,
    ...levelMethods,
    ...handbookMethods,
    initModal(){
    },
    showHierarchyModal() {
            this.modal_payload = this.form;
            console.log(JSON.parse(JSON.stringify(this.modal_payload)))
            this.show_hierarchy_modal = true;
            setTimeout(()=>{
                this.$bvModal.show('levelLinkingModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
                        this.show_hierarchy_modal = false;
                    }
                });
            }, 1)
        },
    updateLevelIds(new_ids){
        this.form.level_ids = new_ids;
        console.log(JSON.parse(JSON.stringify(this.form)))
        this.$bvModal.hide('levelLinkingModal')
    },
    selectedOwner(item) {
        let email = item.split('|')[1];
        let email_string = email.trim();
        let user = this.all_users.find( item => {
            return item.email == email_string
        });
        this.form.user = user.id
    },
    onSubmit(event) {
        event.preventDefault()
        let data = [ this.form ]
        let rows_to_save = [];
            data.forEach(item => {
                console.log(JSON.parse(JSON.stringify(item)))
                let params = {
                    id: -1,
                    level_ids: item.level_ids,
                    parent_id: 0,
                    title: item.title,
                    type: item.document_type,
                    owner_user_id: Number(item.user),
                    review_date: item.review_date
                };
                if (params.parent_id == null) {
                    params.parent_id = 0;
                }
                rows_to_save.push(params);
            });

            let save_params = {
                documents: rows_to_save
            }
            
            this.saveDocument(save_params).then(() => {
                this.$emit('saved')
                this.$bvModal.hide('addDocumentModal')
            })
    },
    populateList(){
        let users = [];
        this.all_users.forEach( user =>{
            users.push(`${user.name} | ${user.email}`)
        });
        this.users = users;
    },
},
beforeDestroy() {
    this.modal_payload = {}
},
mounted(){
    this.initModal();
    this.populateList();
    this.assigned_level_id = this.selected_level.id;
    this.form.level_ids.push(this.selected_level.id) 
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
#input-group-1__BV_label_ {
    font-size: 11px;
}
#input-group-2__BV_label_ {
    font-size: 11px;
}
#input-group-3__BV_label_ {
    font-size: 11px;
}
#input-group-4__BV_label_ {
    font-size: 11px;
}
#input-group-5__BV_label_ {
    font-size: 11px;
}
</style>