<template>
    <b-modal id='assignmentModal' size="lg" hide-footer>
        <template #modal-header>
            <div class="d-flex w-100">
                <h5>{{modalTitle}}</h5>
            </div>
        </template>
        <b-alert v-if="has_mounted == false" show>
            Loading assignment data...
        </b-alert>
        <!-- content -->
        <b-overlay :show="is_loading">
            <div v-if="has_mounted">
                <vue-typeahead-bootstrap
                    ref="userSearch"
                    :data="users"
                    v-model="query"
                    placeholder="Search name/email"
                    showOnFocus
                    @hit="selectedItemEvent"
                    prepend="Users:"
                ></vue-typeahead-bootstrap>
                <webix-ui style="height: 100%" v-model="level_data" :config="ux" ></webix-ui>
            </div>
            
        </b-overlay>
        <!-- footer -->
        <div class='d-flex'>
            <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
        </div>
    </b-modal>

</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import { cloneDeep, findIndex } from 'lodash';
import { levelComputed, handbookComputed, handbookMethods } from '@/state/helpers';
import isString from 'lodash/isString';
export default {
    components:{
        VueTypeaheadBootstrap
    },
    data:() => ({
        modal_mode: 'user', // or 'user'
        users:[],
        checkedUsers: [],
        has_mounted: false,
        level_data:[],
        is_loading : false,

        //* typeahead
        query: '',
    }),
    watch:{
        modal_mode(){
            this.$nextTick(()=>{
                this.setTreeData();
                if(this.modal_mode == 'user'){
                    // create user list for typeahead
                    this.populateList();
                }
            })
        },
        has_mounted(newValue){
            if(newValue){
                setTimeout(()=>{
                    let tree = window.webix.$$('levelLinkingTreeUsers')
                    tree.blockEvent();
                    if(tree) {
                        //* open tree where nodes are checked
                        this.openTreeNode(tree.getChecked());
                        this.selected_document_assignments.forEach( item => {
                            item.users.forEach( user => {
                                let user_array = cloneDeep(this.all_users)
                                let found_user = user_array.find((item) => {return item.id == user.id})
                                if(found_user) {
                                    found_user.user_levels.forEach(( user_level )=>{
                                    let id = `${user_level.id}|user-${user.id}`
                                    // user.blocked from doc flag is flipped
                                    if(tree && item.assigned_to_doc && user.blocked_from_doc == false && tree.exists(id)){
                                        this.checkedUsers.push(user.id);
                                        tree.checkItem(id)
                                        tree.checkItem(user_level.id)
                                    }
                                 })
                                }
                            })
                            if(item.assigned_to_doc && tree.exists(item.id)){
                                tree.checkItem(item.id)
                            }
                        })
                        //* open tree where nodes are checked
                        this.openTreeNode(tree.getChecked());
                    }
                    tree.unblockEvent()
                }, 50)
            }
            
        }
    },
    computed:{
        ...levelComputed,
        ...handbookComputed,
        ux(){
            let el = this;
            return {
                id: 'levelLinkingTreeUsers',
                view: 'tree',
                css: 'levelTree',
                scroll: true,
                drag: false,
                checkValue:1, uncheckValue:0,
                data: [] ,
                template: function(obj, common){
                    if(obj.show_checkbox){
                        return `<span class="ms-1">${common.checkbox(obj, common)} <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-user assignment-user" style="color: rgb(192, 191, 188);"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" class=""></path></svg>  ${obj.name}</span>`
                        //"{common.icon()} {common.checkbox()} #name#"
                    }
                    else{
                        return common.icon(obj, common) + common.checkbox(obj, common) + `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="network-wired" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-network-wired assignment-level" style="color: rgb(192, 191, 188);"><path fill="currentColor" d="M256 64H384v64H256V64zM240 0c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h48v32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96v32H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H240c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H192V288H448v32H400c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H560c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H512V288h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V192h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H240zM96 448V384H224v64H96zm320-64H544v64H416V384z" class=""></path></svg>` + ` ${obj.name}`
                    }
                } ,
                ready(){
                    this.sort('#show_checkbox#');
                    //! Removed context menu
                    //if(window.webix.$$('assignmentContextMenu')){
                    //    window.webix.$$('assignmentContextMenu').destructor();
                    //}

                    let user_array = cloneDeep(el.all_users)
                    user_array.forEach(( user )=>{ 
                        user['show_checkbox'] = true;
                        // fix bug where user_levels is just empty object when not assigned to level
                        if(Array.isArray(user.user_levels)){
                            user.user_levels.forEach(( user_level )=>{
                                let branch_id = user_level.id
                                if(this.exists(branch_id)){
                                    let mutated_user = cloneDeep(user);
                                    mutated_user.id = `${branch_id}|user-${mutated_user.id}`
                                    //*insert user leaf into the linked level branch
                                    //*{ user_object, level_id (parent)}
                                    this.add(mutated_user, 0,branch_id);
                                }
                            })
                        }
                    })
                    //! Removed context menu
                    // let els = this.$scope.$parent.$parent
                    /*window.webix.ui({
                        view:'contextmenu',
                        id: 'assignmentContextMenu',
                        on:{
                            onMenuItemClick: (id) => {
                                switch(id){
                                    case 'Select only Level':{                                        
                                        window.assignment_modal_context.selectLevelOnly(window.assignment_modal_context.right_clicked_id)
                                        break;
                                    }
                                    case 'Select only Users':{
                                        window.assignment_modal_context.selectUsersOnly(window.assignment_modal_context.right_clicked_id, this.data)
                                        break;
                                    }
                                }
                            }
                        }
                    })
                    .attachTo(this);
                    */


                    //* expand first node
                    this.open(this.getFirstId());
                },
                on:{
                     //eslint-disable-next-line
                     onBeforeContextMenu:function(id, e, node){
                        el.right_clicked_id = id;
                        let ctx_menu =  window.webix.$$('assignmentContextMenu');
                        if(ctx_menu != undefined){
                            ctx_menu.clearAll();
                            let options = el.contextMenuItems;
                            ctx_menu.define('data', options);
                            ctx_menu.refresh();
                        }
                    },

                    onItemCheck(rowID, colID){
                        if(colID == true){ 
                            this.data.eachSubItem( rowID,(children) => {        
                                    this.checkItem(children.id)
                            })  
                        }
                        else{
                            this.data.eachSubItem( rowID,(children) => {
                                    this.uncheckItem(children.id)
                            })
                        }
                        let tree = window.webix.$$('levelLinkingTreeUsers')
                        tree.blockEvent();
                        if(isNaN(rowID)) {
                            //this.checkItem(levelValue)
                            if(colID == true){ 
                                el.toggleItemEvent(rowID, true)
                            } else {
                                el.toggleItemEvent(rowID, false)
                            }
                        }
                        tree.unblockEvent();
                    }
                },
                
            }
        },
        contextMenuItems(){
                return [
                    'Select only Level',
                    'Select only Users',
                ]
        },        
        modalTitle(){
            return "Assign Users/Levels";
        },
        hasAssignments(){
            //* check if the current document has assignments already added
            let valid = false;
            let doc = this.documents.find( item => item.id == this.document.id)
            if(doc){
                valid = doc.assignments.length ? true : false
            }
            return valid;
        }
    },
    methods:{
        ...handbookMethods,
        showHelp(){
            this.$swal.fire('Select a Organisational Structure Level to assign the whole level, or unselect the users that should be hidden from the assignment');
        },
        selectLevelOnly(id) {
            let tree = window.webix.$$('levelLinkingTreeUsers');
            tree.blockEvent();
            let levelValue = id.split('|')[0]
            if(isString(id)){
                tree.checkItem(levelValue)
            } else {
                tree.checkItem(id)
            }
            tree.unblockEvent();
        },
        selectUsersOnly(id, data) {
            let tree = window.webix.$$('levelLinkingTreeUsers');
            tree.blockEvent();
            let levelValue = id.split('|')[0]
            if(isString(id)){
                tree.checkItem(levelValue)
                data.eachSubItem( levelValue,(children) => { 
                    if(isString(children.id)) {
                        let childID = children.id.split('|')[0];
                        if(childID == levelValue) {
                           tree.checkItem(children.id)
                        }  
                    }
                })
            } else {
                data.eachSubItem( id,(children) => {          
                    tree.checkItem(children.id)
                })
            }
            tree.unblockEvent();
        },
        logEvent(type, message, args){
            console.log(type);
            console.log(args);
        },
        save(){
            this.is_loading = true;
            let payload = {
                document_id: this.document.id,
                user_ids: [],
            }
            let tree = window.webix.$$('levelLinkingTreeUsers');
            let users = tree.getChecked().filter( id => isString(id));
            let users_ids_list = []
            users.forEach(user => {
                let user_string = user.split('|')[1];
                let user_id = user_string.split('-')[1];
                users_ids_list.push(Number(user_id))
            })
            // get LEVEL items
            // let level_ids = tree.getChecked().filter( id => !isString(id));
            // console.log(level_ids)
            // level_ids.forEach( item => {
            //     //insert level id into save payload -- could also just have payload.level_ids = level_ids 
            //     payload.level_ids.push(item);
            //     console.log(tree.data.getBranch(item))
            //     //get branches for each selected level
            //     let branches = tree.data.getBranch(item).filter( leaf => { return leaf.type == undefined && !leaf.checked });
            //     branches.map( leaf => {
            //         console.log('Leaf', leaf)
            //         // id looks like this -> 54|user-43  -> {level_id}|user-{user_id}
            //         payload.blocked_user_ids.push(leaf.id.split('|user-')[1]);
            //     })
            //     //payload.blocked_user_ids.push(value)
            // }) 
            payload.user_ids = users_ids_list
            payload.user_ids = [...new Set(payload.user_ids)]
            console.log(payload)
            this.saveDocumentAssignments(payload)
            .then( () => {
                this.is_loading = false;
                this.$bvModal.hide('assignmentModal');
                this.$swal.fire({
                    icon: 'success',
                    title: 'Saved Document Assignment',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                })
                let doc_id = this.document.id;
                this.loadDocuments(this.selected_level.id).then(()=>{
                    this.loadDocument(doc_id);
                })
            })
            .catch( () => { this.is_loading = false });
            this.$emit('updated')
            
        },
        setMode(type){
            if(window.webix.$$('levelLinkingTreeUsers')){
                window.webix.$$('levelLinkingTreeUsers').destructor();
            }
            this.$nextTick(()=>{
                this.modal_mode = type;
            })
        },
        showModalModeChangePrompt(type){
            if(this.modal_mode == type){
                return;
            }
            else{
                this.setMode(type);
            }
        },
        openTreeNode(id_array){
            let tree = window.webix.$$('levelLinkingTreeUsers');
            if(tree){
                id_array.forEach( id => {
                    let item = tree.getItem(id);
                    if(item != undefined){
                        tree.open(item.$parent);
                        item = tree.getItem(item.$parent);
                    }
                })
            }
        },

        processTreeWithUsers(data){
            console.log(JSON.parse(JSON.stringify(data)))
            data.forEach((element) => {
                // give element the required fields
                element["parent"] = element.parent_id; //used for webix related stuff
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id != null) {
                //* If element is a child element, push to children array.
                    //let users_array = [];
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            ele['flagged'] = true;
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                            //let branch_users = cloneDeep(d.users);
                            //branch_users.forEach( user => {
                            //    user.id = `${d.id}|user-${user.id}`
                            //    user['show_checkbox'] = true;
                            //    d.data.push(user)
                            //    //users_array.push(user);
                            //});
                            //d.data = d.data.concat(d.branch_users)
                        }
                        else{
                            //TODO - verify this fixed duplicates not being removed
                            let index = findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }
                        }
                    });
                    //users_array.forEach( item => {
                    //    ele.data.push(item);
                    //})
                }
            });
            //* Remove the leftover / duplicate elements
            //data = data.filter((ele) => ele.parent_id != ele.id);
            //data = data.filter((ele) => ele.parent_id == null);
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            this.$nextTick(()=>{
                this.has_mounted = true;
            })
            return data;
        },
        setTreeData(){
            this.has_mounted = false;
                this.level_data = [];
                this.$nextTick(()=>{
                    let value = cloneDeep(this.selected_document_assignments)
                    this.level_data = this.processTreeWithUsers(value);
                })
        },

        //* typeahead
        selectedItemEvent(item){
            console.log(item)
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let email = item.split('| ')[1];
            let user = this.all_users.find( user => {
                return user.email == email;
            });
            
            if(user){
                let matches = [];
                this.selected_document_assignments.forEach( level => {
                    level.users.forEach( level_user => {
                        if(user.id == level_user.id){
                            //* id for user nodes follow the format -> {level_id}|user-{user_id}
                            matches.push(`${level.id}|user-${level_user.id}`);
                        }
                    })
                })
                if(matches.length > 0){
                    let tree = window.webix.$$('levelLinkingTreeUsers');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            tree.checkItem(match)
                        }
                    })
                    console.log(matches)
                    this.openTreeNode(matches);
                    this.resetTypeahead();
                }
                else{
                    this.$swal.fire('User not found')
                }
            }
        },
        toggleItemEvent(item, check){
            console.log(item)
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let user_string = item.split('|')[1];
            let user_id = user_string.split('-')[1];
            let user = this.all_users.find( user => {
                return user.id == user_id;
            });
            
            if(user){
                let matches = [];
                let levelArray = []
                this.selected_document_assignments.forEach( level => {
                    level.users.forEach( level_user => {
                        if(user.id == level_user.id){
                            //* id for user nodes follow the format -> {level_id}|user-{user_id}
                            matches.push(`${level.id}|user-${level_user.id}`);
                            if(check) {
                                matches.push(`${level.id}`);
                            } else {
                                //check is level has childeren selected
                                let tree = window.webix.$$('levelLinkingTreeUsers');
                                let users = tree.getChecked().filter( id => isString(id));
                                users.forEach(user => {
                                  let level_id = user.split('|')[0];
                                  let user_string = user.split('|')[1];
                                  let user_id = user_string.split('-')[1];
                                  if(user_id != level_user.id) {
                                    levelArray.push(Number(level_id))
                                  }
                               })
                               if(!levelArray.includes(level.id)) {
                                 matches.push(`${level.id}`);
                               }
                            }
                        }
                    })
                })
                if(matches.length > 0){
                    let tree = window.webix.$$('levelLinkingTreeUsers');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            if(check) {
                                tree.checkItem(match)
                            } else {
                                tree.uncheckItem(match)
                            }
                           
                        }
                    })
                    console.log(matches)
                    this.openTreeNode(matches);
                    this.resetTypeahead();
                }
                else{
                    this.$swal.fire('User not found')
                }
            }
        },
        resetTypeahead(){
            this.$nextTick(()=>{
                this.query = '';
                let input = this.$refs['userSearch'].$refs['input'];
                input.blur();
            })
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                console.log(JSON.parse(JSON.stringify(user)))
                if(!user.archived) {
                    users.push(`${user.name} | ${user.email}`)
                }
            });
            this.users = users;
        },
    },
    beforeDestroy() {
        window.assignment_modal_context = undefined;
    },
    mounted(){
        window.assignment_modal_context = this;
        let params = {
            level_id: this.company_id,
            document_id: this.document.id,
        }
        this.getDocumentAssignments(params)
        .then(()=>{
            this.populateList();
            this.setTreeData();    
        })
        
    },
}
</script>

<style>
.webix_view.webix_window.webix_popup.webix_menu{
    z-index: 1041 !important;
}
.levelTree {
    max-height: 300px
}
.assignment-level {
    margin-left: 3px;
    padding-bottom: 1px;
}
.assignment-user {
    padding-bottom: 1px;
}

</style>