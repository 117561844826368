<template>
    <div>
        <div v-for="user in active_users" :key="user.id" class="bg-soft border-top border-light ps-1 pe-1" :class="getBackgroundClass(user.status, user.inbox_user_id)">
            <div v-b-toggle="'expand-'+user.id" class="d-flex" style="font-size:11px;" :style="showClickableCursor(user.status)" :title="getTitleType(user.status)">
                <span v-if="isLoggedInUser(user.inbox_user_id) && user.status == 'sent'">
                    <div>
                        <strong>{{user.inbox_user_name}}</strong> 
                    </div>
                    A new draft has been published and requires your attention!
                </span>
                <span v-else>
                    {{user.inbox_user_name}} <span v-if="user.accepted_date != 'N/A'">({{formattedDate(user.accepted_date)}})</span>
                </span>
                
                <font-awesome-icon  class="fa-fw pt-1 ms-auto" :icon="getIconType(user.status)"/>
            </div>
            <b-collapse :id="'expand-'+user.id">
                <span v-if="user.status == 'rejected'" style="font-size:11px;">
                    <strong>Reason: </strong> 
                    <span>{{user.reason}}</span>
                </span>
            </b-collapse>
            <div v-show="isLoggedInUser(user.inbox_user_id) && user.status == 'sent'" class="pb-1">
                <b-overlay :show="is_saving">
                    <span>
                        <b-button variant="success" size="sm" class="me-1 pt-0 pb-0" @click="setStatus('accepted', user.id)">
                            Accept
                        </b-button>
                        <b-button variant="danger" size="sm" class="pt-0 pb-0" @click="setStatus('rejected', user.id)">
                            Reject
                        </b-button>
                    </span>
                </b-overlay>
                    <!--<span class="badge bg-success text-dark me-1">Accept</span>
                    <span class="badge bg-success text-dark badge-secondary">Reject</span>-->
            </div>
        </div>
        <b-overlay :show="updating_users" no-wrap></b-overlay>
    </div>
</template>

<script>
import { adminComputed, handbookComputed, } from '@/state/helpers'
import { format } from 'date-fns'
// eslint-disable-next-line
import { uniqBy, findIndex, orderBy } from 'lodash';
export default {
    props:{
        users:{
            required: true,
        }
    },
    data:() => ({
        is_saving: false,
        updating_users: false,
    }),
    computed:{
        ...adminComputed,
        ...handbookComputed,
        active_users(){
            
            //* remove duplicate users
            let filtered_users = uniqBy(this.users, 'inbox_user_id');
            //* order by first name
            filtered_users = orderBy(filtered_users, ['inbox_user_name'], ['asc']);

            let index = findIndex(filtered_users, { inbox_user_id: this.core.id});
            if(index > -1){
                //* set current user as first item
                filtered_users.unshift(filtered_users.splice(index, 1)[0]);
            }
            //* get users that are active ( not archived -> backend shouldnt return this)
            return filtered_users.filter( user => {
                return user.inbox_user.active == 1
            })
        }
    },
    watch:{
        users(){
            console.log("UPDATING USERS")
            this.updating_users = true;
            setTimeout(()=>{
                this.updating_users = false;
            }, 400)
        }
    },
    methods:{
        formattedDate(date) {
            if(date != 'N/A') {
                return format(new Date(date), 'dd-MMM-yyyy');
            } else {
                return ''
            }
        },
        setStatus(type, notification_id){
            this.is_saving = true;
            let payload = {
                status: type,
                inbox_id: notification_id,
            };
            this.$eventHub.$emit('updatedStatus', payload);
        },
        isLoggedInUser(user_id){
            return this.core.id == user_id;
        },
        getTitleType(status){
            switch(status){
                case 'sent':{
                    return 'Pending Acceptance'
                }
                case 'accepted':{
                    return 'Accepted'
                }
                case 'rejected':{
                    return 'Rejected'
                }
            }
        },
        getIconType(status){
            switch(status){
                case 'sent':{
                    return 'fa-solid fa-envelope'
                }
                case 'accepted':{
                    return 'fa-solid fa-check'
                }
                case 'rejected':{
                    return 'fa-solid fa-times'
                }
            }
        },
        getBackgroundClass(status, user_id){
            switch(status){
                case 'sent':{
                    if(this.isLoggedInUser(user_id)){
                        return 'bg-info'
                    }
                    else{
                        return ''
                    }
                }
                case 'accepted':{
                    return 'bg-success'
                }
                case 'rejected':{
                    return 'bg-danger'
                }
            }
        },
        showClickableCursor(status){
            if(status == 'rejected'){
                return 'cursor: pointer !important; text-decoration: underline grey;'
            }
            else{
                return 'cursor: default !important;'
            }
        }
    },
    mounted(){},
}
</script>

<style>

</style>