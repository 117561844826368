<template>
    <div>
        <b-alert :show="document.linked_documents.length == 0" class="m-2">
            No Documents Linked.
        </b-alert>
        <div v-b-tooltip title="" v-for="(linkedDocs, idx) of document.linked_documents" :key="idx">
            <div style="max-height: 50px">
            <ul class="mt-1">
                <li ><strong v-show="linkedDocs.can_user_access" class="cursor-pointer" @click="viewDoc(linkedDocs.fetch_token, linkedDocs.title)">{{linkedDocs.title}} </strong> 
                    <strong v-show="!linkedDocs.can_user_access" class="unlinkable">{{linkedDocs.title}} (No Access)</strong> 
                <font-awesome-icon v-show="linkedDocs.can_user_access" class="cursor-pointer" @click="showAddLinkModal(linkedDocs.fetch_token, linkedDocs.title)" :icon="['fas', 'link']" /></li>
            </ul>
        </div>
        </div>
        <div class="m-2">
            <b-button block v-if="isUserOwnerOfDocument()" variant="primary" class="w-100" size="sm" @click="showModal()">
                Manage Linked Documents
            </b-button>
        </div>
        <linkDocumentsModal v-if="show_link_document_modal" :payload="modal_payload" /> 
        <addLinkModal @handleInsert="handleInsert" v-if="show_add_link_document_modal" :payload="link_payload" />        
    </div>
</template>

<script>
import { handbookMethods, handbookComputed, adminComputed } from '@/state/helpers'
import linkDocumentsModal from './modals/linkDocumentModal.vue';
//import simplebar from 'simplebar-vue'
import addLinkModal from './modals/addLinkModal.vue'
export default {
    components:{
        linkDocumentsModal,
        addLinkModal,
        //simplebar
    },
    payload:{
        required: true,
    },
    data:() => ({
        show_link_document_modal: false,
        show_add_link_document_modal: false,
        modal_payload: {},
        link_payload: {}
    }),
    computed:{
        ...handbookComputed,
        ...adminComputed
    },
    methods:{
        ...handbookMethods,
        isUserOwnerOfDocument(){
            let co_author_index = this.document.co_author_users.findIndex( user => {
                return Number(user.id) == Number(this.core.id);
            });
            let is_co_author = co_author_index != -1;
            let is_owner = Number(this.core.id) == Number(this.document.owner_user_id);
            return is_owner || is_co_author ;
        },
        viewDoc(token, title){
            //* push route to process/{document_id}
            this.$swal.fire({
                title: "Open Document " + `"${title}"`,
                text: "Are you sure you want to open this document.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.loadDocumentByToken(token).then(() => {
                        //this.$emit('refreshDocuments')
                    })
                }
            });
        },
        showModal(){
            console.log(JSON.parse(JSON.stringify(this.document)))
            this.modal_payload = {document: this.payload}
            console.log(this.modal_payload)
            this.$nextTick(()=>{
                this.show_link_document_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('linkDocumentModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "linkDocumentModal") {
                            this.show_link_document_modal = false;
                        }
                    });
                })
            })
        },
        showAddLinkModal(id, name){
            this.$nextTick(()=>{
                this.link_payload = {id: id, name: name}
                this.show_add_link_document_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('addLinkModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "addLinkModal") {
                            this.show_add_link_document_modal = false;
                        }
                    });
                })
            })
        },
        handleInsert(data) {
            this.$emit('handleInsert', data)
        }
    },
    mounted(){
        
    },
}
</script>

<style>
.red {
    color:red
}
.unlinkable {
    color: red
}
</style>