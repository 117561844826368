<template>
    <b-modal id="linkDocumentModal" hide-footer title="Link Document" style="height: 100%;">
        <template #modal-header>
            <div class="w-100 d-flex" >
                <span style="font-weight: bold; margin-left: 2px">Link Document</span>
                <span v-if="$hasPerm('Power User')" class="form-check form-switch me-3 ms-auto" style="font-size: 10px;">
                </span>
                <font-awesome-icon @click="$bvModal.hide('linkDocumentModal')" style="cursor: pointer; padding-top: 2px;" icon="fa-times"></font-awesome-icon>

            </div>
        </template>
        
        <!-- <div class="modal-title">
           Details   
        </div> -->
        <div>
                <!-- <div class="pb-1">  
                    <b-button variant="lightr" size="sm">
                        <font-awesome-icon icon="fa-arrow-left"/>
                    </b-button>
                    <span class="ms-1">Link Document</span>
                </div> -->
                <b-overlay :show="is_loading">
                <!-- <div class="d-flex">
                    <b-form-input v-model.lazy="filter_input" placeholder="Search Targets"></b-form-input>
                    <b-button class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div> -->
                <div class="d-flex">
                    <div class="modal-title m-1">
                        <span v-show="selectedName != ''">Selected {{ selectedType }} : </span> 
                        <span v-show="selectedType == 'objective'" class="badge-item badge-item-objective mr-1"></span>
                        <span v-show="selectedType == 'target'" class="badge-item badge-item-target mr-1"></span>
                        <!-- <span class="badge-item badge-item-objective mr-1"></span> -->
                        <span style="font-weight: bold" class="text-primary">{{selectedName}}</span>
                    </div>
                </div>

                <div class="d-flex">
                    <b-form-input size="sm" v-model.lazy="filter_input" placeholder="Search Document"></b-form-input>
                    <b-button size="sm" variant="warning" @click="filter_input = ''" class="ms-1"><font-awesome-icon icon="fa-times"></font-awesome-icon></b-button>
                </div>
                    
                <webix-ui style="height: 100%;" :config="ui" v-model="level_data" ></webix-ui>
                <b-button type="submit" class='ms-auto mt-2 m-1' style="float: right" variant='primary' @click="saveChanges">Save Changes</b-button>
                </b-overlay>
            </div>
    </b-modal>
</template>

<script>
import dateMixin from '@/mixins/dateMixin.js'
//import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
//mport draggable from 'vuedraggable';
import { levelComputed, peopleComputed, peopleMethods, handbookMethods, handbookComputed, activityComputed } from '@/state/helpers';
import { cloneDeep} from 'lodash'
import _ from 'lodash'
export default {
mixins: [dateMixin],
components: {
    //VueTypeaheadBootstrap
},
props:{
    payload:{
        required: true,
    },
},
data:() => ({
    is_loading: false,
    is_loading_all_linkable_plans: false,
    linkable_plans_data: [],
    show_all_linkable_plans: false,
    filter_input: '',
    users_example:[],
    combined_targets_array: [],
    selectedPlainId: 0,
    combined_objectives_array: [],
    change_objective_mode: false,
    canSearch: false,
    parent_name: '',
    notes: '',
    changesMade: false,
    selectedName: '',
    selectedType: '',
    viewChildren: false,
    level_data: [],
    query: '',
    docs: []
}),
watch:{
    change_objective_mode: {
        handler() {
            if(this.change_objective_mode == true) {
                this.is_loading = true
                console.log('building the tree')
                this.setTreeData();
                this.initflippedModal();
                
            } else {
                window.webix.$$('levelDocumentsTree').destructor();
            }
        }
    },
    filter_input(newValue){
        let tree = window.webix.$$('levelDocumentsTree');
        if(tree){
            tree.filter("#name#", newValue);
        }
    },
    selectedPlainId: {
        handler(newValue, oldValue){
            console.log("!!!!!!");
            console.log(newValue)
            console.log(oldValue)
            let tree = window.webix.$$('levelDocumentsTree');
            //Remove the styling for previous item (we only have the plain id of the previous item)
            let objective_options = this.goal_objectives_tree;                  
                let objective_index = _.findIndex(objective_options, {id: Number(oldValue)}); 
                if(objective_index != -1){
                    let objective = objective_options[objective_index]
                    let oldValueId = `${this.payload.level_id}|objective-${objective.id}`
                    if(oldValueId && tree.exists(oldValueId)){
                        tree.removeCss(oldValueId, 'selected_style_details_modal')
                    }
                } else {
                    let target_options = this.targets;                  
                    let target_index = _.findIndex(target_options, {id: Number(oldValue)});
                    if(target_index != -1){
                        let target = target_options[target_index];
                        let oldValueId = `${this.payload.level_id}|target-${target.id}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        if(oldValue && tree.exists(oldValue)){
                            tree.removeCss(oldValue, 'selected_style_details_modal')
                        }
                    }
                }
            //set the new styling 
            if(this.selectedPlainId){
                if(tree){//* will be und
                    if(this.selectedType == 'objective') {
                        let value = `${this.payload.level_id}|objective-${newValue}`
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        
                    } else if(this.selectedType == 'target') {
                        let value = `${this.payload.level_id}|target-${newValue}`
                        console.log(value)
                        if(tree.exists(value)){
                            console.log("getting in here")
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                        let oldValueId = `${this.payload.level_id}|target-${oldValue}`
                        if(oldValueId && tree.exists(oldValueId)){
                            tree.removeCss(oldValueId, 'selected_style_details_modal')
                        }
                    } else {
                        let value = newValue
                        if(tree.exists(value)){
                           tree.addCss(value, 'selected_style_details_modal');
                        }
                    }
                    
                }
                /*if(window.webix.$$('userLevelsTree').exists)
                window.webix.$$('userLevelsTree').addCss(newValue, 'selected_tree_item')
                if(oldValue != undefined){
                    window.webix.$$('userLevelsTree').removeCss(oldValue, 'selected_tree_item')
                }*/
            }
        }
    },
},
computed:{
    ...levelComputed,
    ...peopleComputed,
    ...handbookComputed,
    ...activityComputed,
    ui(){
            let el = this;
            return{
                id: 'levelDocumentsTree',
                view: 'tree',
                css: 'levelTree',
                //scroll: true,
                minHeight: 500,
                maxHeight: 700,
                width: 500,
                scroll:"y",
                drag: false,
                select: true,
                // //data: this.level_data,
                template: (obj, common) => {
                    let id = obj.id
                    if(isNaN(id)) {
                        let plainId = id.split('-')[1]
                        if(id.includes('document')) {
                            if(el.document.id == plainId) {
                                return `<div class="text-primary click-not-allowed ms-1"><span style="width: 13px; height:13px;" class="d-inline-flex"></span> ${obj.title} (Current Document)</div>`;
                            } else {
                                return `<span class="ms-1 checkboxStyling">` + common.icon(obj, common) + `<span class="checkboxStyling">`+ common.checkbox(obj, common) + `</span><span class="ms-1"><i class="fas fa-file"></i></span><span class="ms-1 normal-text tree_styles"> ${obj.title} - <span style="font-weight: bold">${obj.owner_name}</span></span> </span>`;
                            }
                        }                      
                    } else {
                        return common.icon(obj, common) + `<span class="normal-text tree_styles">${obj.name}`;
                    }
                },
                //maxHeight: 500,
                filterMode:{
                    showSubItems:false,
                },
                ready(){
                    let document_array = cloneDeep(el.companyDocuments);
                    document_array.forEach(( document )=>{ 
                        let branch_id = document.level_ids
                        if(branch_id.length > 0) {
                            //branch_id.forEach(level_id => {
                                if(this.exists(branch_id[0])){
                                    let mutated_document = cloneDeep(document);
                                    mutated_document.id = `${branch_id}|document-${mutated_document.id}`
                                    mutated_document.name = mutated_document.title
                                    console.log(mutated_document.id);
                                    this.add(mutated_document, 0,branch_id);
                                }
                            //})
                        } else {
                            if(this.exists(branch_id)){
                                let mutated_document = cloneDeep(document);
                                mutated_document.id = `${branch_id}|document-${mutated_document.id}`
                                mutated_document.name = mutated_document.title
                                console.log(mutated_document.id);
                                this.add(mutated_document, 0,branch_id);
                            }
                        }
                       
                    })

                    let item = this.getItem(el.selected_level.id);
                    //item['parent'] = 0
                    console.log(JSON.parse(JSON.stringify(item)))
                    let tree = window.webix.$$('levelDocumentsTree');
                    if(tree) {
                        while(item.$parent != 0){
                            /* open parent, then set current item */
                            this.open(item.$parent);
                            
                            item = this.getItem(item.$parent);
                        }
                        el.$nextTick(() => {
                            let item1 = this.getItem(el.selected_level.id);
                            this.open(item1.id)
                            tree.showItem(item1.id)
                        })
                        
                    }
                   
                },
                on:{
                    // onBeforeOpen(id) {
                    //     let num = Number(id)
                    //     if(num != isNaN) {
                    //        console.log(id)
                    //        el.level_to_load = Number(id)
                    //     }
                    // }
                },
            }
        },
},
methods:{
    ...handbookMethods,
    ...peopleMethods,
    saveChanges() {
            let tree = window.webix.$$('levelDocumentsTree');
            let checked_items = tree.getChecked();
            let params = {
                document_id: this.document.id,
                linked_documents:[],
            }
            checked_items.forEach( item_id => {
                // each user node has the id format of {level_id}|user-{user_id};
                params.linked_documents.push(item_id.split('-')[1]);
            })
            this.is_saving = true;
            this.saveLinkedDocument(params)
            .then(()=>{
                this.$swal.fire({
                    icon:'success',
                    title:'Updated Linked Documents',
                    toast: true,
                    position: 'top',
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true,
                });
                this.loadDocument(this.document.id)
                .then(()=>{
                    this.$bvModal.hide('linkDocumentModal');
                })
                .catch(()=>{
                    this.is_saving = false;
                })
            })
            .catch(()=>{
                this.is_saving = false;
            })
    },
    selectedItemEvent(item){
            //* assumes there is a '|' character as a delimeter (included a blank space as well)
            let doc = this.companyDocuments.find( doc => {
                return doc.title == item;
            });
            
            if(doc){
                let matches = [];
                console.log(doc)
                // if(doc.level_ids.length > 1) {
                //     this.doc.level_ids.forEach( id => {
                //             if(id == doc.id){
                //                 //* id for user nodes follow the format -> {level_id}|user-{user_id}
                //                 matches.push(`${id}|document-${doc.id}`);
                //             }
                //     })
                // } else {
                    matches.push(`${doc.level_ids[0]}|document-${doc.id}`);
                //}
 
                if(matches.length > 0){
                    let tree = window.webix.$$('levelDocumentsTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            tree.checkItem(match)
                        }
                    })
                    console.log(matches)
                    this.openTreeNode(matches);
                    this.resetTypeahead();
                }
                else{
                    this.$swal.fire('User not found')
                }
            }
        },
    setTreeData(){
        let temp_level_data = _.cloneDeep(this.levels);
        //temp_level_data = _.orderBy(temp_level_data, ['item_order']);
        this.level_data = this.processLevels(temp_level_data);
    },
    processLevels(levels){
            this.show = false;
            var flat_array = levels != null ? _.cloneDeep(levels) : [];
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            if(flat_array.length == 0){
                this.show = true;
                return [];
            }
            pre_processed_array = _.uniqBy(flat_array, 'id');
            pre_processed_array = pre_processed_array.map((item) => {
                let new_item = item;
                // give element the required fields
                new_item["parent"] = new_item.parent_id; //used for webix related stuff
                new_item["value"] = new_item.name; //used for webix related stuff
                new_item['data'] = []; // array of children for the node
                map[item.id] = { ...new_item, };
                return new_item;
            });
            pre_processed_array.forEach((item) => {
                if (item.parent_id !== null) {
                    if (map[item.parent_id]) {
                        map[item.parent_id].data.push(map[item.id]);
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                }
                else {
                    nestedArray.push(map[item.id]);
                }
            });
            this.show = true;
            return nestedArray;
    },
    initflippedModal(){
        this.is_loading = true
        setTimeout(() => {
            let matches = []
            //
            let data = null
            if(this.document.linked_documents && this.document.linked_documents.length > 0) {
                 data = this.document.linked_documents 
                 data.forEach(item => {
                    this.companyDocuments.forEach(doc => {
                        if(doc.id == item.id) {
                            let modified = `${doc.level_ids[0]}|document-${item.id}`
                            matches.push(modified);
                        }
                    })
                 })
            }
            // if(matches.length == 0) {
            //     matches.push(this.selected_level.id)
            // }
            if(matches.length > 0){
                let tree = window.webix.$$('levelDocumentsTree');
                    matches.forEach( match => {
                        if(tree.exists(match)) {
                            let promises = [ this.loadDataFromParents(match) ];
                            Promise.all(promises)
                            .then(()=>{
                                setTimeout(() => {
                                    //tree.addCss(matches[0], 'selected_style_details_modal');
                                    console.log(match)
                                    this.openTreeNode(match, true);
                                    //this.resetTypeahead();
                                }, 800) 
                                
                            })
                        } else {
                            this.is_loading = false
                        }
                    })
            } else {
                this.is_loading = false
            }
        }, 1500)
    },
    loadDataFromParents(id_array) {
        console.log(id_array)
        let tree = window.webix.$$('levelDocumentsTree');
        let data = []
        if(tree){
            let item = tree.getItem(id_array);
            while(item.$parent != 0){
                data.push(item.$parent);
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
        }
    },
    openTreeNode(id_array, searched){
        console.log(id_array)
        let tree = window.webix.$$('levelDocumentsTree');
        if(tree){
            let item = tree.getItem(id_array);
            //tree.closeAll()
            while(item.$parent != 0){
                tree.open(item.$parent);
                item = tree.getItem(item.$parent);  
            }
            
            if(searched) {
                tree.checkItem(id_array);
            }
            this.is_loading = false
        }
    },
    populateList(){
        let docs = [];
        this.companyDocuments.forEach( doc =>{
            docs.push(`${doc.title}`)
        });
        this.docs = docs;
    },
},
beforeDestroy() {
    if(window.webix.$$('levelDocumentsTree')) {
        window.webix.$$('levelDocumentsTree').destructor();
    }
    this.modal_payload = {}
},
mounted(){
    this.is_loading = true
    this.selectedId = this.selected_level_id
    this.populateList()
    this.setTreeData();
    this.$nextTick(() => {
        this.initflippedModal();
    })
    
},
}
</script>
<style>
#kanbanModal___BV_modal_header_ {
    background-color: #EBEDF0;
}
.low_color {
    background-color: rgb(107, 168, 203) !important;
}
.medium_color {
    background-color: #f1734f !important;
}
.high_color {
    background-color: red !important;
}
.label {
    font-weight: bold;
}
.checkboxStyling .webix_tree_checkbox {
    padding-top: 3px
}
.selected_style_details_modal  {
    /* border: 2px black !important; */
    color: #0275d8 !important;
    font-weight: bold;
}
#movementHistoryList.verti-timeline .event-list {
    padding: 0px 0px 10px 30px;
}
.greyOut {
    color: grey !important
}
</style>