<template>
    <div>
        <b-alert show v-show="document.co_author_users.length == 0" class="m-2">
            No co-authors added.
        </b-alert>
        <div v-show="document.co_author_users.length > 0" class="mt-1">
            <ul>
                <li v-for="user in userList" :key="user.id" v-bind:class="payload.some(e => e.id == user.id) ? 'green': ''" style="font-size: 1rem;">
                    
                    <span style="font-size: 0.7rem;">
                        {{user.name}} {{user.last_name}} <span v-if="user.is_owner">(Owner)</span>
                    </span>
                    
                </li>
            </ul>
            <hr>
        </div>

        <div class="m-2">
            <b-button block :disabled="!isUserOwnerOfDocument()" variant="primary" class="w-100" size="sm" @click="showModal()">
                Manage Co-Authors
            </b-button>
        </div>

        <CoAuthorModal v-if="show_modal"/>

    </div>
</template>

<script>
import { handbookMethods, handbookComputed, adminComputed } from '@/state/helpers'
import CoAuthorModal from './modals/coAuthorModal.vue'
export default {
    components:{
        CoAuthorModal
    },
    data:() => ({
        show_modal: false,
    }),
    props: {
        payload: {
            required: false
        }
    },
    computed:{
        ...handbookComputed,
        ...adminComputed,
        userList() {
            let users = this.document.co_author_users.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.id === value.id
                ))
            )
            return users
        }
    },
    methods:{
        ...handbookMethods,
        showModal(){
            this.show_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('coAuthorModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "coAuthorModal") {
                        this.show_modal = false;
                    }
                });
            })
        },
        isUserOwnerOfDocument(){
            let co_author_index = this.document.co_author_users.findIndex( user => {
                return Number(user.id) == Number(this.core.id);
            });
            let is_co_author = co_author_index != -1;
            let is_owner = Number(this.core.id) == Number(this.document.owner_user_id);
            return is_owner || is_co_author ;
        },
    },
    mounted(){
        console.log(this.payload)
        
    },
}
</script>

<style>
.red {
    color:red
}
.green {
    color: green
}
</style>