<template>
    <b-modal hide-footer centered id="purposeModal" title="New Purpose">
        <b-overlay :show="isLoading">
            <b-form @submit.stop.prevent="savePurpose" >
                <label for="textarea">Purpose Text</label>
                <b-form-textarea v-model="purpose" no-resize rows="5">

                </b-form-textarea>
                <div class="text-end pt-5 mt-3">
                    <b-button variant="light" @click="$bvModal.hide('purposeModal')">Close</b-button>
                    <b-button @click="savePurpose()" variant="success" class="ms-1">Save</b-button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { levelComputed, handbookMethods, handbookComputed } from "@/state/helpers";
export default {
    data:() => ({
        isLoading: false,
        purpose: '',
    }),
    computed:{
        ...levelComputed,
        ...handbookComputed,
    },
    methods:{
        ...handbookMethods,
        save(){
            this.isLoading = true;
            let params = {
                document_id: this.document.id,
                comment: this.comment,
            }
            this.saveComment(params)
            .then(()=>{
                this.loadDocuments(this.selected_level.id)
                .then(()=>{
                    this.loadDocument(this.document.id)
                    .then(()=>{
                        this.$bvModal.hide('purposeModal');
                    }).catch(()=>{ this.isLoading = false; })
                }).catch(()=>{ this.isLoading = false; })
            }).catch(()=>{ this.isLoading = false; })
        },
        savePurpose() {
            let rows_to_save = [];
            let item = this.document
            console.log(JSON.parse(JSON.stringify(item)))
            // let ids = []
            // if(item.levels.length > 1) {
            //     item.levels.forEach((level => {
            //         ids.push(level.id)
            //     }))
            // } else {
            //     ids.push(item.levels[0].id)
            // }
            
            let params = {
                id: item.id,
                level_ids: item.linked_level_ids,
                parent_id: item.parent_id == item.id ? 0 : item.parent_id,
                title: item.title,
                type: item.type,
                purpose: this.purpose,
                owner_user_id: Number(item.owner_user_id),
                review_date: item.review_date
            };
            if (params.parent_id == null) {
                params.parent_id = 0;
            }
            rows_to_save.push(params);
            console.log(JSON.parse(JSON.stringify(rows_to_save)))

            let save_params = {
                documents: rows_to_save
            }
            this.saveDocument(save_params)
            .then(()=>{
                this.loadDocuments(this.selected_level.id)
                .then(()=>{
                    this.loadDocument(this.document.id)
                    .then(()=>{
                        this.$bvModal.hide('purposeModal');
                    }).catch(()=>{ this.isLoading = false; })
                }).catch(()=>{ this.isLoading = false; })
            }).catch(()=>{ this.isLoading = false; })
        }
    },
    mounted(){
        this.purpose = this.document.purpose
    },
}
</script>

<style>

</style>